import React, { Dispatch, SetStateAction, useState } from 'react';

import { useFetchAuthorizedPermissions } from '@jobandtalent/auth-api-client-v2';

import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useSession } from 'next-auth/react';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

interface MenuUserInterface {
  avatar: string;
  name: string;
  permissions: string[];
}

interface MenuInterface {
  className: string;
  onChangeCollapsed: Dispatch<SetStateAction<boolean>>;
  user: MenuUserInterface;
}

// Currently there's direct window usage inside Menu, this breaks SSR, workaround - import it dynamically
const DynamicBackofficeNavigation = dynamic<MenuInterface>(
  () => import('@jobandtalent/backoffice-navigation').then((mod) => mod.Menu),
  { ssr: false }
);

const Layout = ({ children }: LayoutProps) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const { data: authorizedPermissions } = useFetchAuthorizedPermissions();
  const { data: session } = useSession();
  const user = session?.user;

  return (
    <>
      {user && authorizedPermissions ? (
        <DynamicBackofficeNavigation
          className={styles.menu}
          onChangeCollapsed={setIsMenuCollapsed}
          user={{
            avatar: user.picture_url,
            name: user.name,
            permissions: authorizedPermissions.data.user_permissions
          }}
        />
      ) : null}
      <main
        className={classnames(styles.main, {
          [styles.menuCollapsed]: isMenuCollapsed
        })}
      >
        {children}
      </main>
    </>
  );
};

export default Layout;
