import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';
import { SWRConfig } from 'swr';

import paths from 'src/common/paths.json';
import Layout from 'src/components/Layout/Layout';

import Auth from './_auth';
import AxiosInterceptors from './_axiosInterceptors';
import 'src/styles/global.scss';

const { publicRuntimeConfig } = getConfig();

if (['production', 'staging'].includes(publicRuntimeConfig.APP_ENV)) {
  datadogRum.init(publicRuntimeConfig.datadogRumConfig);
}

function App({ Component, pageProps, router }: AppProps) {
  if (router.pathname === paths.CHECK) {
    return <Component />;
  }

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="stylesheet" href="https://use.typekit.net/mui0guq.css" />
      </Head>

      <RollbarProvider config={publicRuntimeConfig.rollbarConfig}>
        <ErrorBoundary>
          <SessionProvider session={pageProps.session}>
            <SWRConfig
              value={{
                revalidateIfStale: false,
                revalidateOnFocus: false,
                revalidateOnReconnect: false
              }}
            >
              <Auth>
                <AxiosInterceptors>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </AxiosInterceptors>
              </Auth>
            </SWRConfig>
          </SessionProvider>
        </ErrorBoundary>
      </RollbarProvider>
    </>
  );
}

export function reportWebVitals({ name, value }: NextWebVitalsMetric) {
  if (['production', 'staging'].includes(publicRuntimeConfig.APP_ENV)) {
    switch (name) {
      case 'FCP':
        datadogRum.addTiming('first-contentful-paint', value);
        break;
      case 'LCP':
        datadogRum.addTiming('largest-contentful-paint', value);
        break;
      case 'CLS':
        datadogRum.addTiming('cumulative-layout-shift', value);
        break;
      case 'FID':
        datadogRum.addTiming('first-input-delay', value);
        break;
      case 'TTFB':
        datadogRum.addTiming('time-to-first-byte', value);
        break;
      case 'Next.js-hydration':
        datadogRum.addTiming('hydration', value);
        break;
      case 'Next.js-route-change-to-render':
        datadogRum.addTiming('route-changed', value);
        break;
      case 'Next.js-render':
        datadogRum.addTiming('render-finished', value);
        break;
      default:
        break;
    }
  }
}

export default App;
