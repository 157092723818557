import { useEffect } from 'react';

import { signIn, signOut, useSession } from 'next-auth/react';

import LoaderFullSize from 'src/components/LoaderFullSize/LoaderFullSize';

interface AuthProps {
  children: JSX.Element;
}

export default function Auth({ children }: AuthProps) {
  const { data: session } = useSession({
    required: true,
    onUnauthenticated() {
      signIn('authonio');
    }
  });

  useEffect(() => {
    if (session?.error) {
      signOut();
    }
  }, [session]);

  if (!session || session.error) {
    return <LoaderFullSize />;
  }

  return children;
}
