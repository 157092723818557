import React from 'react';

import { Spinner } from '@jobandtalent/design-system';

import styles from './LoaderFullSize.module.scss';

const LoaderFullSize = () => (
  <div className={styles.container}>
    <Spinner theme="blue" type="regular" />
  </div>
);

export default LoaderFullSize;
