import { useEffect, useState } from 'react';

import { AXIOS_INSTANCE } from '@jobandtalent/auth-api-client-v2';

import { InternalAxiosRequestConfig } from "axios";
import getConfig from 'next/config';
import { signOut, useSession } from 'next-auth/react';

import LoaderFullSize from 'src/components/LoaderFullSize/LoaderFullSize';

const { publicRuntimeConfig } = getConfig();

interface AxiosInterceptorsProps {
  children: JSX.Element;
}

export default function AxiosInterceptors({ children }: AxiosInterceptorsProps) {
  const [isReady, setReady] = useState(false);
  const { data: session } = useSession();

  useEffect(() => {
    const requestInterceptorId = AXIOS_INSTANCE.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers.setAuthorization(`Bearer ${session?.accessToken}`);

        return {
          ...config,
          baseURL: publicRuntimeConfig.AUTH_URL + `/api/v2`,
        };
      }
    );

    const responseInterceptorId = AXIOS_INSTANCE.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          signOut();
        }
        return Promise.reject(error);
      }
    );

    setReady(true);

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(requestInterceptorId);
      AXIOS_INSTANCE.interceptors.response.eject(responseInterceptorId);
    };
  }, [session]);

  return isReady ? children : <LoaderFullSize />;
}
